[data-theme="light"] {
  --header-background-color: #00695C;
  --header-border-color: #5c766b;
  --header-menu-item-color: #ffffff;

  --content-background-primary: #ffffff; 
  --content-background-accent: #2a9387;
  --content-background-warning: #C38A70;
  --content-background-color-2: #2e385614;

  --main-content-background-color: #f8f8f3;
  --box-background-color-primary: #ffffff;

  --text-color-primary: #00695C;
  --text-color-accent: #768474;
  --text-color-warning: #C38A70;
  --text-color-light: #FFFF;

  --border-color-primary: rgb(226, 222, 222);
  --border-color-2: #FFFF;

  --btn-background-color-primary: #00695C;
  --btn-background-color-accent: #edeef3ff;
  --btn-background-color-warning: #C38A70;

  --btn-text-color-primary: #FFFF;
  --btn-text-color-accent: #768474;
  --btn-text-color-warning: #C38A70;

}

[data-theme="dark"] {
  --header-background-color: #417690;
  --header-border-color: #417690;
  --header-menu-item-color: #434141;

  --content-background-primary: #121212; 
  --content-background-accent: #768474;
  --content-background-warning: #C38A70;
  --content-background-color-2: #2e385614;

  --main-content-background-color: #434141;
  --box-background-color-primary: #212121;

  --text-color-primary: #e0e0e0;
  --text-color-accent: #81d4fa;
  --text-color-warning: #C38A70;
  --text-color-light: #FFFF;

  --border-color-primary: rgb(189, 193, 172);
  --border-color-2: #FFFF;

  --btn-background-color-primary: #417690;
  --btn-background-color-accent: #417690;
  --btn-background-color-warning: #C38A70;

  --btn-text-color-primary: #FFFF;
  --btn-text-color-accent: #768474;
  --btn-text-color-warning: #C38A70;
}
