/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
@import 'themes';

html, body { height: 100%; }
body { 
  transition: margin 0.25s cubic-bezier(0, 0, 0.3, 1) 0s; 
  margin-left: 0px;
  font-family: -apple-system, -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
}

.ais-primary-btn {
  background-color: var(--btn-background-color-primary) !important;
  border-color:  var(--border-color-primary) !important;
  color: var(--btn-text-color-primary) !important;

  &:hover {
    box-shadow: 0 8px 9px -4px rgba(59,113,202,.3), 0 4px 18px 0 rgba(59,113,202,.2);
  }
}

.ais-secondary-btn {
  background-color: var(--btn-background-color-accent) !important;
  color: var(--text-color-primary) !important;

  &:hover {
    box-shadow: 0 8px 9px -4px rgba(59,113,202,.3), 0 4px 18px 0 rgba(59,113,202,.2);
  }
}

.page-title {
  color: var(--text-color-primary);
  font-size: 25px;
}

.card-shadow {
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.06), 0 5px 5px 0 rgba(0,0,0,0.03), 0 5px 5px 0 rgba(0,0,0,0.02), 0 4px 4px 0 rgba(0,0,0,0.02), 0 2px 2px 0 rgba(0,0,0,0.02);

  &:hover {
    box-shadow: 0 64px 64px 0 rgba(0,0,0,0.06),0 32px 32px 0 rgba(0,0,0,0.03),0 16px 16px 0 rgba(0,0,0,0.02),0 4px 4px 0 rgba(0,0,0,0.02),0 2px 2px 0 rgba(0,0,0,0.02);
  }
}

